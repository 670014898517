.App {
  text-align: center;
  background-image: url("background.png");
  background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100vw 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiInputBase-input{
  border-radius: 14px;
  background-color: rgba(245, 245, 245, 0.8);
}

.jss1, .jss2 {
  background: transparent!important;
}

.MuiContainer-root{
  background: rgb(246, 246, 246);
}
.jss6 {
  max-width: 712px!important;
  border-radius: 14px;
}
.jss6 form{
  margin-top: 40px;
}
.MuiFilledInput-underline:before {
  display: none;
}
.MuiInputBase-root{

  border-radius: 14px!important;
}

.MuiFormControl-root.jss9{
  flex-direction: row;
    width: calc(100% - 55px);
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 14px;
    padding: 10px 27px;
    align-items: center;
}
.MuiFormControl-root.jss9 .MuiFormControl-root, .MuiSelect-root, .MuiFilledInput-root, .MuiAutocomplete-root{
  width: 100%;
}

.MuiFormControl-root.jss9 .MuiInputBase-root{
  background: transparent!important;
}
.MuiFormLabel-root{
  top: -17px!important;
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 16px!important;
}
@media (max-width: 700px){
  .MuiFormHelperText-root{
    font-size: 0.5rem!important;
  }
  .jss28 {
      width: calc(100% - 54px)!important;
  }
}
.jss8{
  letter-spacing: 0.5px;
    line-height: 150%;
    font-size: 18px;
}
.platforms-select-option:last-of-type{
  font-style: italic;
}

.white-space{
    white-space: break-spaces!important;
}


.live {
    background-image: url('live.png');
}
.lunch {
    background-image: url('lunch.png');
}
.meeting {
    background-image: url('meeting.png');
}
.network {
    background-image: url('network.png');
}
.afterparty {
    background-image: url('afterparty.png');
}
.workshops {
    background-image: url('workshops.png');
}

.features {
    list-style: none;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0;
}

.features li  {
    padding: 10px  20px 10px 40px;
    width: auto;
    background-repeat: no-repeat;
    background-size: auto 55%;
    background-position: 7px center;
    border: 1px solid black;
    border-radius: 8px!important;
    color: black;
    opacity: .5;
    flex-grow: 1;
    text-align: center;
}
